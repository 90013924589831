/**
 * Type: ページ
 * What: ヒルトン福岡限定ページLP
 */
import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import BreadcrumbComponent from '../components/Breadcrumb';
import Image from '../util/Image';

const pageTitle = 'ヒルトン宿泊者限定ページ';
const pageDescription =
  'ヒルトン福岡シーホークご宿泊者様限定特典対象アトラクション10%オフ！ドームツアー200円オフ！';
const pageSlug = 'hiltonfukuoka';

// Data Props Template
const ticketHowPage = () => (
  <Layout pageSlug={pageSlug}>
    <SEO
      title={pageTitle}
      description={pageDescription}
      meta={[
        {
          name: 'robots',
          content: 'noindex, nofollow',
        },
      ]}
    />
    <BreadcrumbComponent hierarchy={2} bread2title={pageTitle} />

    <section className="section">
      <div className="container">
        <div className="inner-slim trans">
          <h1 className="u-pc:fz-28  u-pc:fz-20 u-ta-c">
            <b>
              ヒルトン福岡シーホークご宿泊者様限定特典
              <br />
              対象アトラクション10%オフ！ドームツアー200円オフ！
            </b>
          </h1>

          <div className="u-mt-10">
            <Image src="hiltonfukuoka-kv.jpg" />
          </div>

          <p className="u-mt-10">
            <b>
              ヒルトン福岡シーホークご宿泊者様限定で、BOSS E・ZO
              FUKUOKAの対象アトラクションを10%オフにてご提供いたします。
              <br />
              また、ドームの魅力を最大限味わえる「ドームツアー」に200円オフでご参加いただけます。
              <br />
              ヒルトン福岡シーホークからドームデッキ直結でお越しいただけますので、ご宿泊の機会にぜひともご利用くださいませ。
            </b>
          </p>
        </div>
      </div>
    </section>

    <section className="section">
      <div className="container">
        <h2 className="title is-3">
          <span>対象アトラクション、ご利用料金</span>
        </h2>
        <div className="inner-slim trans">
          <div className="page-group">
            {/* 絶景3兄弟 「すべZO SMBC日興証券」 */}
            <article>
              <h3 className="floor-ttl title is-6 fwb tac mb-4 sp-mb0">
                <span className="mb0 floorNo">
                  <span>R</span>
                  <span>F</span>
                </span>
                <a href="/zekkei-brothers/subezo/" className="tx-link">
                  絶景3兄弟 「すべZO SMBC日興証券」
                </a>
              </h3>
              <div className="flex mt-5">
                <Image src="img-group-usage-8.jpg" className="half sp-mb10" />
                <div className="half flex-justify-center">
                  <p className="text is-2 is-3-sp mb-2">
                    地上40mの高さからビルの壁面に沿って地上まで一気に滑り降りる全長100mのチューブ型スライダー。体験できるのは日本でBOSS
                    E･ZO FUKUOKAだけ！
                  </p>
                  <p className="list-mark">
                    ※建造物に付随したチューブ型スライダーとして日本初
                  </p>
                </div>
              </div>

              <div className="ticket-table">
                <div className=" table-base price-table mt-5">
                  <table>
                    <tbody>
                      <tr>
                        <th colSpan={2}>絶景3兄弟 「すべZO SMBC日興証券」</th>
                      </tr>
                      <tr>
                        <td>ピーク</td>
                        <td>1,350円（定価:1,500円）</td>
                      </tr>
                      <tr>
                        <td>レギュラー</td>
                        <td>1,080円（定価:1,200円）</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="btn-wrap mt-5 mb-5">
                <a
                  href="https://e-zofukuoka.com/zekkei-brothers/subezo/"
                  className="btn-regular"
                >
                  <span>詳細・注意事項はこちら</span>
                  <svg
                    viewBox="0 0 490.8 490.8"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M135.685,3.128c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l227.115,227.136L120.581,472.461c-4.237,4.093-4.354,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667c4.164-4.165,4.164-10.917,0-15.083L135.685,3.128z" />
                    <path d="M128.133,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115L120.581,18.232c-4.171-4.171-4.171-10.933,0-15.104c4.171-4.171,10.933-4.171,15.104,0l234.667,234.667c4.164,4.165,4.164,10.917,0,15.083L135.685,487.544C133.685,489.551,130.967,490.68,128.133,490.68z" />
                  </svg>
                </a>
              </div>
            </article>

            {/* Sanrio characters Dreami!ng Park */}
            <article className="u-mt-60">
              <h3 className="floor-ttl title is-6 fwb tac mb-4 sp-mb0">
                <span className="mb0 floorNo">
                  <span>7</span>
                  <span>F</span>
                </span>
                <a
                  href="https://e-zofukuoka.com/dreamingpark/"
                  className="tx-link"
                >
                  Sanrio characters Dreami!ng Park
                </a>
              </h3>
              <div className="flex mt-5">
                <Image
                  src="hiltonfukuoka-doripa.jpg"
                  className="half sp-mb10"
                />
                <div className="half flex-justify-center">
                  <p className="text is-2 is-3-sp mb-2">
                    サンリオのキャラクターたちと友達のように集い、同じ目線でいつもでも遊べる「共産型コミュニケーションパーク」。楽しいゲームに夢中になったり、かわいい空間で一緒に写真を撮影したり、思い出をグッズにして持ち帰ったりして思い思いの時間を過ごそう！
                  </p>
                </div>
              </div>

              <div className="page-ticket u-mt-15">
                <div className="table-base">
                  <table className="price-table">
                    <tbody>
                      <tr>
                        <th colSpan="2">Sanrio characters Dream!ng Park</th>
                      </tr>
                      <tr>
                        <td colSpan="2">
                          <p>入場料金：無料</p>
                          <p className="mt-1">
                            コンテンツプレイ料金：コンテンツ共通券：450円/１枚（定価:500円）
                          </p>
                          <p className="mt-3 tal">
                            ※チケット1枚につき、5つのコンテンツから1つをお選びいただけます。
                          </p>
                          <p className="mt-1 tal">
                            ※「Dream!ng SMILES AR
                            SNAP」は1組1枚、その他のコンテンツは、お1人様につき1枚のチケットが必要です。
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="btn-wrap mt-5 mb-5">
                <a
                  href="https://e-zofukuoka.com/dreamingpark/"
                  className="btn-regular"
                >
                  <span>詳細・注意事項はこちら</span>
                  <svg
                    viewBox="0 0 490.8 490.8"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M135.685,3.128c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l227.115,227.136L120.581,472.461c-4.237,4.093-4.354,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667c4.164-4.165,4.164-10.917,0-15.083L135.685,3.128z" />
                    <path d="M128.133,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115L120.581,18.232c-4.171-4.171-4.171-10.933,0-15.104c4.171-4.171,10.933-4.171,15.104,0l234.667,234.667c4.164,4.165,4.164,10.917,0,15.083L135.685,487.544C133.685,489.551,130.967,490.68,128.133,490.68z" />
                  </svg>
                </a>
              </div>
            </article>

            {/* V-World AREA クレディ・アグリコル */}
            <article className="u-mt-60">
              <h3 className="floor-ttl title is-6 fwb tac mb-4 sp-mb0">
                <span className="floorNo mb0">
                  <span>6</span>
                  <span>F</span>
                </span>
                <a href="https://e-zofukuoka.com/v-world/" className="tx-link">
                  V-World AREA クレディ・アグリコル
                </a>
              </h3>

              <div className="flex mt-5">
                <Image src="img-group-usage-4.jpg" className="half sp-mb10" />
                <div className="half flex-justify-center">
                  <p className="text is-2 is-3-sp mb-2">
                    和の雰囲気に彩られた空間で、様々なバーチャルコンテンツが体験できる最新施設！
                  </p>
                </div>
              </div>

              <div className="page-ticket u-mt-15">
                <div className="table-base">
                  <table className="price-table">
                    <tbody>
                      <tr>
                        <th colSpan="3">V-World AREA クレディ・アグリコル</th>
                      </tr>

                      <tr className="sub">
                        <th colSpan="3">2時間チケット</th>
                      </tr>
                      <tr>
                        <td rowSpan="2">23歳以上</td>
                        <td>ピーク</td>
                        <td>2,700円（定価:3,000円）</td>
                      </tr>
                      <tr>
                        <td>レギュラー</td>
                        <td>2,520円（定価:2,800円）</td>
                      </tr>
                      <tr>
                        <td rowSpan="2">13~22歳</td>
                        <td>ピーク</td>
                        <td>2,070円（定価:2,300円）</td>
                      </tr>
                      <tr>
                        <td>レギュラー</td>
                        <td>1,980円（定価:2,200円）</td>
                      </tr>
                      <tr>
                        <td rowSpan="2">12歳以下</td>
                        <td>ピーク</td>
                        <td>
                          1,620円 ※<sup>1</sup>（定価:1,800円）
                        </td>
                      </tr>
                      <tr>
                        <td>レギュラー</td>
                        <td>
                          1,530円 ※<sup>1</sup>（定価:1,700円）
                        </td>
                      </tr>

                      <tr className="sub">
                        <th colSpan="3">30分チケット</th>
                      </tr>
                      <tr>
                        <td rowSpan="2">23歳以上</td>
                        <td>ピーク</td>
                        <td>1,080円（定価:1,200円）</td>
                      </tr>
                      <tr>
                        <td>レギュラー</td>
                        <td>900円（定価:1,000円）</td>
                      </tr>
                      <tr>
                        <td rowSpan="2">13~22歳</td>
                        <td>ピーク</td>
                        <td>855円（定価:950円）</td>
                      </tr>
                      <tr>
                        <td>レギュラー</td>
                        <td>720円（定価:800円）</td>
                      </tr>
                      <tr>
                        <td rowSpan="2">12歳以下</td>
                        <td>ピーク</td>
                        <td>
                          540円※<sup>1</sup>（定価:600円）
                        </td>
                      </tr>
                      <tr>
                        <td>レギュラー</td>
                        <td>
                          450円※<sup>1</sup>（定価:500円）
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="btn-wrap mt-5 mb-5">
                <a
                  href="https://e-zofukuoka.com/v-world/"
                  className="btn-regular"
                >
                  <span>詳細・注意事項はこちら</span>
                  <svg
                    viewBox="0 0 490.8 490.8"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M135.685,3.128c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l227.115,227.136L120.581,472.461c-4.237,4.093-4.354,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667c4.164-4.165,4.164-10.917,0-15.083L135.685,3.128z" />
                    <path d="M128.133,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115L120.581,18.232c-4.171-4.171-4.171-10.933,0-15.104c4.171-4.171,10.933-4.171,15.104,0l234.667,234.667c4.164,4.165,4.164,10.917,0,15.083L135.685,487.544C133.685,489.551,130.967,490.68,128.133,490.68z" />
                  </svg>
                </a>
              </div>
            </article>

            {/* チームラボフォレスト 福岡 - SBI証券 */}
            <article className="u-mt-60">
              <h3 className="floor-ttl title is-6 fwb tac mb-4 sp-mb0">
                <span className="floorNo mb0">
                  <span>5</span>
                  <span>F</span>
                </span>
                <a href="/teamlabforest/" className="tx-link">
                  チームラボフォレスト 福岡 - SBI証券
                </a>
              </h3>

              <div className="flex mt-5">
                <Image src="img-group-usage-1.jpg" className="half sp-mb10" />
                <div className="half flex-justify-center">
                  <p className="text is-2 is-3-sp mb-2">
                    チームラボフォレスト 福岡 -
                    SBI証券は、アートコレクティブ・チームラボによる「捕まえて集める森」と「運動の森」からなる新しいミュージアムです。
                  </p>
                </div>
              </div>

              <div className="page-ticket u-mt-15">
                <div className="table-base">
                  <table className="price-table">
                    <tbody>
                      <tr>
                        <th colSpan="3">チームラボフォレスト 福岡 - SBI証券</th>
                      </tr>

                      <tr>
                        <td rowSpan="2">
                          大人
                          <br />
                          16歳以上
                        </td>
                        <td>ピーク</td>
                        <td>2,430円（定価:2,700円）</td>
                      </tr>
                      <tr>
                        <td>レギュラー</td>
                        <td>2,160円（定価:2,400円）</td>
                      </tr>

                      <tr>
                        <td rowSpan="2">
                          小人
                          <br />
                          4歳〜15歳
                        </td>
                        <td>ピーク</td>
                        <td>
                          1,080円※<sup>1</sup>（定価:1,200円）
                        </td>
                      </tr>
                      <tr>
                        <td>レギュラー</td>
                        <td>
                          900円※<sup>1</sup>（定価:1,000円）
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="btn-wrap mt-5 mb-5">
                <a
                  href="https://e-zofukuoka.com/teamlabforest/"
                  className="btn-regular"
                >
                  <span>詳細・注意事項はこちら</span>
                  <svg
                    viewBox="0 0 490.8 490.8"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M135.685,3.128c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l227.115,227.136L120.581,472.461c-4.237,4.093-4.354,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667c4.164-4.165,4.164-10.917,0-15.083L135.685,3.128z" />
                    <path d="M128.133,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115L120.581,18.232c-4.171-4.171-4.171-10.933,0-15.104c4.171-4.171,10.933-4.171,15.104,0l234.667,234.667c4.164,4.165,4.164,10.917,0,15.083L135.685,487.544C133.685,489.551,130.967,490.68,128.133,490.68z" />
                  </svg>
                </a>
              </div>
            </article>

            {/* 王貞治ベースボールミュージアム */}
            <article className="u-mt-60">
              <h3 className="floor-ttl title is-6 fwb tac mb-4 sp-mb0">
                <span className="floorNo mb0">
                  <span>4</span>
                  <span>F</span>
                </span>
                <a href="/obm/" className="tx-link">
                  王貞治ベースボールミュージアム
                  <br />
                  Supported by DREAM ORDER
                </a>
              </h3>
              <div className="flex mt-5">
                <Image src="img-group-usage-9.jpg" className="half sp-mb10" />
                <div className="half flex-justify-center">
                  <p className="text is-2 is-3-sp">
                    <span className="fwb">【ヒストリーゾーン】</span>
                    野球人・王貞治の球史を振り返る展示品や映像を最新の手法で表現。王さんの波乱万丈の道のりを描きます。
                  </p>
                  <p className="text is-2 is-3-sp mt-5">
                    <span className="fwb">【89パーク】</span>
                    王さんの野球への想いを体験型アトラクションに凝縮。打つ、投げる、捕るなど様々なチャレンジが楽しめます。
                  </p>
                </div>
              </div>
              <div className="page-ticket u-mt-15">
                <div className="table-base">
                  <table className="price-table">
                    <tbody>
                      <tr>
                        <th colSpan="3">
                          王貞治ベースボールミュージアム
                          <br />
                          Supported by DREAM ORDER
                        </th>
                      </tr>
                      <tr>
                        <td>16歳以上</td>
                        <td>ピーク／レギュラー</td>
                        <td>1,620円（定価:1,800円）</td>
                      </tr>
                      <tr>
                        <td>15歳以下</td>
                        <td>ピーク／レギュラー</td>
                        <td>
                          810円※<sup>2</sup>（定価:900円）
                        </td>
                      </tr>

                      <tr className="sub">
                        <th colSpan="3">89パーク</th>
                      </tr>
                      <tr>
                        <td rowSpan="2">16歳以上</td>
                        <td>ピーク</td>
                        <td>1,080円（定価:1,200円）</td>
                      </tr>
                      <tr>
                        <td>レギュラー</td>
                        <td>900円（定価:1,000円）</td>
                      </tr>
                      <tr>
                        <td rowSpan="2">15歳以下</td>
                        <td>ピーク</td>
                        <td>
                          540円※<sup>2</sup>（定価:600円）
                        </td>
                      </tr>
                      <tr>
                        <td>レギュラー</td>
                        <td>
                          450円※<sup>2</sup>（定価:500円）
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="btn-wrap mt-5 mb-5">
                <a href="https://e-zofukuoka.com/obm/" className="btn-regular">
                  <span>詳細・注意事項はこちら</span>
                  <svg
                    viewBox="0 0 490.8 490.8"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M135.685,3.128c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l227.115,227.136L120.581,472.461c-4.237,4.093-4.354,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667c4.164-4.165,4.164-10.917,0-15.083L135.685,3.128z" />
                    <path d="M128.133,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115L120.581,18.232c-4.171-4.171-4.171-10.933,0-15.104c4.171-4.171,10.933-4.171,15.104,0l234.667,234.667c4.164,4.165,4.164,10.917,0,15.083L135.685,487.544C133.685,489.551,130.967,490.68,128.133,490.68z" />
                  </svg>
                </a>
              </div>
              <div className="u-mt-20">
                <p>※1 3歳以下無料（要保護者同伴）</p>
                <p>
                  ※2
                  6歳以下無料、12歳以下のご利用には要保護者同伴（大人チケットの購入が必要）
                </p>
                <p>※2 小学1年生より有料</p>
              </div>
            </article>

            {/* ドームツアー、ご利用料金 */}
            <article className="u-mt-60">
              <h3 className="floor-ttl title is-6 fwb tac mb-4 ">
                ドームツアー、ご利用料金
              </h3>
              <style>
                {`
                  @media screen and (max-width: 767px) {
                    .sp-column_reverse {
                      display: flex;
                      flex-direction: column-reverse;
                    }
                  }
                `}
              </style>

              <p className="u-pc">◆ドーム満喫コース（一例）</p>
              <div className="flex mt-5 u-sp:mt-5">
                <Image
                  src="hiltonfukuoka-dome_tuor.jpg"
                  className="half sp-mb10"
                />
                <p className="u-sp">◆ドーム満喫コース（一例）</p>
                <div className="half flex-justify-center">
                  <p className="text is-2 is-3-sp">
                    普段は入れない選手たちが使うベンチやロッカールームなどのドームの裏側を見学できる、まさにドームの魅力を最大限に満喫できるコースです。
                  </p>
                  <p className="text  mt-5">
                    おとな（高校生以上）：1,400円（定価：1,600円）
                    <br />
                    こども（中学生以下）：650円（定価：850円）
                    <br />
                    ※保護者同伴に限り3歳以下無料
                  </p>
                </div>
              </div>

              <div className="btn-wrap mt-5 mb-5">
                <a
                  href="https://dometour.softbankhawks.co.jp/"
                  className="btn-regular"
                >
                  <span style={{ whiteSpace: 'nowrap' }}>
                    その他コース・注意事項はこちら
                  </span>
                  <svg
                    viewBox="0 0 490.8 490.8"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M135.685,3.128c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l227.115,227.136L120.581,472.461c-4.237,4.093-4.354,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667c4.164-4.165,4.164-10.917,0-15.083L135.685,3.128z" />
                    <path d="M128.133,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115L120.581,18.232c-4.171-4.171-4.171-10.933,0-15.104c4.171-4.171,10.933-4.171,15.104,0l234.667,234.667c4.164,4.165,4.164,10.917,0,15.083L135.685,487.544C133.685,489.551,130.967,490.68,128.133,490.68z" />
                  </svg>
                </a>
              </div>
            </article>

            {/* チケット購入方法 */}
            <article className="u-mt-60">
              <h3 className="floor-ttl title is-6 fwb tac mb-4 sp-mb0">
                チケット購入方法
              </h3>

              <div className="u-mt-20">
                <h4>
                  <b>◆アトラクション</b>
                </h4>
                <div className="u-mt-10">
                  <p>
                    ・BOSS E・ZO
                    FUKUOKA専用チケットサイトまたは館内券売機にて、クーポンコード
                    <b className="u-c-red">【HTS2400】</b>
                    をご入力のうえご購入ください。
                  </p>
                  <p>
                    ・クーポンコードご入力で、
                    <b className="u-c-red">10%オフの優待価格</b>
                    で対象アトラクションをご利用可能です。
                  </p>
                  <p>
                    ※クーポンコード未入力の場合は、10%オフを適用できません。
                  </p>
                </div>
                <div className="btn-wrap mt-5 mb-5">
                  <a
                    href="https://ticket.e-zofukuoka.com/?_gl=1*qme2f5*_ga*MTk5Nzg1Nzc5Ni4xNzE0NjExMzMw*_ga_98VS3CH9S7*MTcyNjE4MTYxOC4zMTQuMS4xNzI2MTgxOTk0LjEzLjAuMA..#/"
                    className="btn-regular btn-buy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>チケット購入はこちら</span>
                    <img
                      src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNSIgaGVpZ2h0PSIxMSIgdmlld0JveD0iMCAwIDI1IDExIj4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsLXJ1bGU6IGV2ZW5vZGQ7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgPC9kZWZzPgogIDxwYXRoIGlkPSLjgrfjgqfjgqTjg5dfNTIxX+OBruOCs+ODlOODvF80IiBkYXRhLW5hbWU9IuOCt+OCp+OCpOODlyA1MjEg44Gu44Kz44OU44O8IDQiIGNsYXNzPSJjbHMtMSIgZD0iTTg1MC4xMTMsMjkwN2gtMy45NDFhMC40MjQsMC40MjQsMCwwLDAtLjM2Ny42OWwzLjk1OCw0LjU1YTAuNCwwLjQsMCwwLDEsMCwuNTNsLTMuOTQ1LDQuNTRhMC40MjUsMC40MjUsMCwwLDAsLjM2OS42OWgzLjk0YTAuNSwwLjUsMCwwLDAsLjM2OC0wLjE2bDQuNC01LjA3YTAuNCwwLjQsMCwwLDAsMC0uNTNsLTQuNDE2LTUuMDhhMC40OTEsMC40OTEsMCwwLDAtLjM2Ny0wLjE2bS03Ljg1MiwwSDgzOC4zMmEwLjQyNCwwLjQyNCwwLDAsMC0uMzY3LjY5bDMuOTU4LDQuNTVhMC40LDAuNCwwLDAsMSwwLC41M2wtMy45NDUsNC41NGEwLjQyNSwwLjQyNSwwLDAsMCwuMzY5LjY5aDMuOTQxYTAuNSwwLjUsMCwwLDAsLjM2Ny0wLjE2bDQuNC01LjA3YTAuNCwwLjQsMCwwLDAsMC0uNTNsLTQuNDE2LTUuMDhhMC40OTEsMC40OTEsMCwwLDAtLjM2Ny0wLjE2bS03Ljg1MiwwaC0zLjk0YTAuNDI0LDAuNDI0LDAsMCwwLS4zNjguNjlsMy45NTgsNC41NWEwLjQsMC40LDAsMCwxLDAsLjUzbC0zLjk0NSw0LjU0YTAuNDI1LDAuNDI1LDAsMCwwLC4zNjkuNjloMy45NDFhMC41LDAuNSwwLDAsMCwuMzY3LTAuMTZsNC40LTUuMDdhMC40LDAuNCwwLDAsMCwwLS41M2wtNC40MTYtNS4wOGEwLjQ5MSwwLjQ5MSwwLDAsMC0uMzY3LTAuMTYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC04MzAgLTI5MDcpIi8+Cjwvc3ZnPgo="
                      alt=""
                    />
                  </a>
                </div>
              </div>

              <div className="u-mt-20">
                <h4>
                  <b>◆ドームツアー</b>
                </h4>
                <div className="u-mt-10">
                  <p>
                    ・BOSS E・ZO
                    FUKUOKA３階インフォメーションにて、ヒルトン福岡シーホークご宿泊者様限定のインビテーションカードをご提示のうえご購入ください。
                  </p>
                  <p>
                    ・インビテーションカードご提示で、
                    <b className="u-c-red">200円オフの優待価格</b>
                    でドームツアーにご参加可能です。
                  </p>
                  <p>
                    ※インビテーションカードのご提示がない場合は、200円オフを適用できません。
                  </p>
                  <p>
                    ※当日の空き状況次第でのご案内となるため、ご参加いただけない可能性がございます。
                  </p>
                </div>
              </div>
            </article>

            {/* 注意事項 */}
            <article className="u-mt-60">
              <h3 className="floor-ttl title is-6 fwb tac mb-4 sp-mb0">
                注意事項
              </h3>

              <div className="u-mt-10">
                <p>
                  ・アトラクションおよびドームツアーともに、他割引との併用はできません。
                </p>
                <p>
                  ・アトラクションおよびドームツアーには利用・参加制限や注意事項がございます。事前にご確認のうえご利用・ご参加ください。
                </p>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default ticketHowPage;
